@import 'styles/import.scss';

.cookies {
  &:after {
    content: '';
    position: fixed;
    top: 0;
    background-color: rgba($blue_dark, 0.35);
    opacity: 0;
    @include tn(opacity, 0.8s, cubic-bezier(0.45, 0.05, 0.55, 0.95), 0s);
    width: 100%;
    z-index: -1;
    height: 100%;
  }
  &.isActive {
    overflow-y: scroll;

    :local(.wrapper) {
      transform: translateX(0%);
    }
    &::after {
      height: 100%;
      z-index: 101;
      width: 100%;
      opacity: 1;
    }
  }
}

.title {
  @include text-lg;
  color: $blue_dark;
  margin-bottom: 12px;
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  background: $white;
  z-index: 102;
  padding: 0 20px 32px;
  margin-left: auto;
  transform: translateX(100%);
  @include tn(transform, 0.8s, cubic-bezier(0.45, 0.05, 0.55, 0.95), 0s);
  overflow-y: auto;
  height: 100vh;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: initial;
    padding: 0 24px 32px;
    width: 400px;
    right: 24px;
    top: 24px;
    bottom: 24px;
    transform: translateX(calc(100% + 24px));
  }
  @include media-breakpoint-up(lg) {
    width: 520px;
  }
}

.oneCookieWrapper {
  margin-bottom: 24px;
}

.nameWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.heading {
  @include text-md;
  color: $blue_dark;
}

.switchWrapper {
  display: flex;
  align-items: center;
}

.switchHelper {
  margin-right: 8px;

  @include media-breakpoint-up(md) {
    margin-right: 24px;
  }
}

.icon {
  width: 20px;
  height: 20px;
  position: relative;
  top: -7px;
  left: 5px;
}

.text {
  @include text-sm;
  margin-bottom: 12px;
  color: $blue_dark;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.expand {
  color: $blue_light;
  display: flex;
  align-items: center;
  @include tn(color, 0.5s, 0s, ease-in-out);
  cursor: pointer;
  @include text-sm;
  &:hover {
    color: $blue;
    svg {
      path {
        fill: $blue;
      }
    }
  }

  svg {
    @include tn(transform, 0.5s, ease-in-out, 0s);

    margin-left: 2px;

    path {
      fill: $blue_light;
    }
  }

  &.isExpanded {
    svg {
      transform: rotate(180deg);
    }
  }
}

.tableWrapper {
  overflow: auto;
}

.table {
  margin-top: 24px;
  border-bottom: 1px solid $grey25;
  width: 100%;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  tr {
    &:last-child {
      th,
      td {
        padding-bottom: 0;
      }
    }
  }

  th {
    @include text-xs;
    font-weight: 400;
    color: $blue_dark;
    padding-bottom: 8px;
    vertical-align: top;
  }

  tbody {
    padding: 16px 0;
    display: block;
    border-top: 1px solid $grey25;

    td {
      @include text-xs;
      color: $blue_dark;
      padding-left: 24px;
      padding-bottom: 8px;
      vertical-align: top;
    }
  }

  @include media-breakpoint-up(md) {
    tbody {
      td {
        padding-left: 48px;
      }
    }
  }
}

.closeForm {
  width: 32px;
  height: 32px;
  cursor: pointer;
  path {
    @include tn(fill, 0.5s, ease-in-out, 0s);

    fill: $blue;
  }
  &:hover {
    path {
      fill: $blue_light;
    }
  }
}

.buttonsWrapper {
  margin-top: 40px;
  @include media-breakpoint-up(lg) {
    margin-top: 56px;

    display: flex;
    justify-content: space-between;
    column-gap: 16px;
  }
}

.button {
  width: 100%;
  + .button {
    margin-top: 16px;
  }
  @include media-breakpoint-up(lg) {
    width: calc(50% - 8px);
    + .button {
      margin-top: 0;
    }
  }
}

.closeIconWrapper {
  height: 48px;
  width: 100%;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
