@import 'styles/import.scss';

.main {
  min-height: 100vh;
}

.backgroundContainer {
  position: relative;
  @include media-breakpoint-up(md) {
    @include make-col(10.5);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(11);
  }
}

.imageBackground {
  height: 742px;
  > span {
    height: 100% !important;
  }
}

.teaser {
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.teaserIcon {
  display: none;
  @include media-breakpoint-up(md) {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 40px;
  }
}

.teaserLogo {
  position: absolute;
  top: 120px;
  left: 40px;
  @include media-breakpoint-up(lg) {
    left: 120px;
  }
}

.pageStaticWrapper {
  padding: 32px 0;

  @include media-breakpoint-up(md) {
    @include make-col(8, 12);

    margin-left: auto;

    padding: 96px 48px 96px 0;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6, 12);
  }

  h2 {
    @include heading-6;

    margin-bottom: 24px;
  }

  h3 {
    @include text-xl;

    margin-bottom: 16px;
    margin-top: 56px;
  }

  h4 {
    @include text-lg;

    margin-bottom: 16px;
  }

  hr {
    position: relative;
    width: 100vw;
    left: calc((100% - 100vw));
    margin-bottom: 48px;
    margin-top: 80px;

    @media (min-width: $container-max-width) {
      left: 0;
      transform: translateX(-50%);
      max-width: $container-max-width;
    }
  }

  p {
    @include text-md;

    margin-bottom: 28px;
  }

  ul {
    @include formatted-li;
    li {
      position: relative;
      padding-left: 28px;
      display: block;
      &::before {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  a {
    color: $blue;
    word-break: break-word;
  }
}
