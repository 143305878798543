@import 'styles/import.scss';

.button {
  @include tn((background, color), 0.5s, 0s, (ease-in, ease-in-out));
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  cursor: pointer;
  @include text-md;
  &:before,
  &:after {
    display: none;
  }
  .icon {
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 50%;
      @include tn(
        (transform, opacity),
        1s,
        cubic-bezier(0.17, 0.13, 0.28, 1),
        0s
      );
      border: 1.5px solid $blue_light;
      backface-visibility: hidden;
      display: block;
      opacity: 1;
    }
  }

  &:hover {
    .icon {
      &:before {
        border-color: transparent;
        transform: rotate(360deg);
        opacity: 0;
        border-right-color: $blue_dark;
        border-bottom-color: $blue_dark;
      }
    }
  }
}

@keyframes circleround {
  0% {
    transform: rotate(-90deg);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0;
  }
}

.primary,
.white,
.blue,
.blueDark {
  color: $blue_light;
  padding: 0;
  .icon {
    &.isNormalIcon {
      svg {
        path {
          fill: $blue_light;
        }

        rect {
          stroke: $blue_light;
        }
      }
    }
    &.isCustomIcon {
      svg {
        g path {
          fill: $blue_light;
        }
        g {
          + path {
            stroke: $blue_light;
          }
        }
      }
    }
  }
  div:first-child {
    &::after {
      background-color: $blue_dark;
    }
  }
  &.isSmall {
    @include text-sm;
    :local(.icon) {
      height: 32px;
      width: 32px;
    }
  }
  & .icon {
    @include tn(background);
    margin-left: 12px;

    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    color: $blue_dark;
    div:first-child {
      border-color: $blue_dark;
    }
    .icon {
      &.isNormalIcon {
        svg {
          path {
            fill: $blue_dark;
          }
          rect {
            stroke: $blue_dark;
          }
        }
      }
      &.isCustomIcon {
        svg {
          g path {
            fill: $blue_dark;
          }
          g {
            + path {
              stroke: $blue_dark;
            }
          }
        }
      }
    }
  }
}

.white {
  color: $white;
  .icon {
    &:before {
      border-color: $white;
    }
    &.isNormalIcon {
      svg {
        path {
          fill: $white;
        }

        rect {
          stroke: $white;
        }
      }
    }
    &.isCustomIcon {
      svg {
        g path {
          fill: $white;
        }
        g {
          + path {
            stroke: $white;
          }
        }
      }
    }
  }

  &:hover {
    color: $green_spot;
    div:first-child {
      border-color: $green_spot;
    }

    .icon {
      &::before {
        border-right-color: $green_spot;
        border-bottom-color: $green_spot;
      }
      &.isNormalIcon {
        svg {
          path {
            fill: $green_spot;
          }

          rect {
            stroke: $green_spot;
          }
        }
      }
      &.isCustomIcon {
        svg {
          g path {
            fill: $green_spot;
          }
          g {
            + path {
              stroke: $green_spot;
            }
          }
        }
      }
    }
  }
}

.blue {
  color: $blue;

  .icon {
    &::before {
      border-color: $blue;
    }
    &.isNormalIcon {
      svg {
        path {
          fill: $blue;
        }

        rect {
          stroke: $blue;
        }
      }
    }
    &.isCustomIcon {
      svg {
        g path {
          fill: $blue;
        }
        g {
          + path {
            stroke: $blue;
          }
        }
      }
    }
  }

  &:hover {
    color: $blue_light;
    div:first-child {
      border-color: $blue_light;
    }
    .icon {
      &::before {
        border-right-color: $blue_light;
        border-bottom-color: $blue_light;
      }
      &.isNormalIcon {
        svg {
          path {
            fill: $blue_light;
          }

          rect {
            stroke: $blue_light;
          }
        }
      }
      &.isCustomIcon {
        svg {
          g path {
            fill: $blue_light;
          }
          g {
            + path {
              stroke: $blue_light;
            }
          }
        }
      }
    }
  }
}

.blueDark {
  color: $blue_dark;
  .icon {
    &::before {
      border-color: $blue_dark;
    }
    &.isNormalIcon {
      svg {
        path {
          fill: $blue_dark;
        }

        rect {
          stroke: $blue_dark;
        }
      }
    }
    &.isCustomIcon {
      svg {
        g path {
          fill: $blue_dark;
        }
        g {
          + path {
            stroke: $blue_dark;
          }
        }
      }
    }
  }

  &:hover {
    color: $blue_light;
    div:first-child {
      border-color: $blue_light;
    }
    .icon {
      &::before {
        border-right-color: $blue_light;
        border-bottom-color: $blue_light;
      }
      &.isNormalIcon {
        svg {
          path {
            fill: $blue_light;
          }

          rect {
            stroke: $blue_light;
          }
        }
      }
      &.isCustomIcon {
        svg {
          g path {
            fill: $blue_light;
          }
          g {
            + path {
              stroke: $blue_light;
            }
          }
        }
      }
    }
  }
}

.filled {
  padding: 14px 20px;
  background-color: $blue;
  color: $white;
  display: block;
  text-align: center;
  & .icon {
    margin-left: 24px;
  }
  &:hover {
    background-color: $blue_light;
    color: $white;
  }
  &.isSmall {
    @include text-sm;
    padding: 11px 20px;
  }
}

.outline {
  padding: 14px 20px;
  background-color: transparent;
  color: $blue_dark;
  border: 1px solid $blue_dark;
  display: block;
  text-align: center;
  & .icon {
    margin-left: 24px;
  }
  &:hover {
    color: $blue_light;
    border-color: $blue_light;
  }
  &.isSmall {
    @include text-sm;
    padding: 11px 20px;
  }
}
