@mixin border-underline {
  position: relative;

  &::after,
  &::before {
    position: absolute;
    top: 100%;
    left: 0%;
    height: 1px;
    background: transparent;
    display: block;
    content: '';
    transition: width 0.5s ease-in-out;
  }
  &::before {
    width: 0;
    background: $blue;
    z-index: 2;
  }
  &::after {
    width: 100%;
    z-index: 1;
  }
  &:hover {
    &::before {
      width: 100%;
    }
  }
}

@mixin hover-border {
  display: inline-block;
  position: relative;
  overflow: hidden;

  &::after {
    @include tn(transform);
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0px;
    left: 0;
    background-color: white;
    transform: translateX(-100%);
  }

  &:hover::after {
    transform: translateX(0);
  }
}

@mixin gradient-underline {
  background: linear-gradient(180deg, transparent 0%, $green_light 0),
    linear-gradient(180deg, transparent 0%, $green 0);
  background-size: 100% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  @include tn((background-size, color), 0.5s, 0s, ease-in-out);
  padding-bottom: 2px;

  display: inline;
  width: fit-content;

  &:hover {
    color: $green;
    background-size: 0 1px, 100% 1px;
  }
}
