@import 'styles/import.scss';

.cookieBanner {
  position: fixed;
  width: fit-content;
  display: none;
  bottom: 0;
  width: 100%;
  &.fadeIn {
    display: block;
    animation: fadeIn 1s forwards;
  }

  &.fadeOut {
    display: block;
    opacity: 0;
    animation: fadeOut 1s forwards;
  }

  > div {
    max-width: initial;
  }

  @include media-breakpoint-up(md) {
    bottom: 24px;
    right: 24px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
  to {
    z-index: 10;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  from {
    visibility: visible;
    z-index: 10;
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
  }
}

.text {
  margin-bottom: 24px;
  @include text-sm;
  color: $blue_dark;
  @include media-breakpoint-up(xl) {
    margin-bottom: 16px;
  }
}

.inner {
  background: $white;
  box-shadow: 0px 4px 4px rgba(14, 14, 15, 0.03),
    0px 8px 12px rgba(14, 14, 15, 0.06);
  padding: 16px 20px 20px;

  @include media-breakpoint-up(md) {
    width: 360px;
    margin-left: auto;
  }
  @include media-breakpoint-up(lg) {
    width: 400px;
  }
}

.title {
  @include text-md;
  color: $blue_dark;
  margin-bottom: 12px;
}

.button {
  width: 100%;
  margin-bottom: 16px;
}

.settings {
  text-align: center;
  color: $blue_light;
  @include text-sm;
  margin: 0 auto;
  cursor: pointer;
  &:hover {
    color: $blue_dark;
  }
}
