@import 'styles/import.scss';

.borderBottom {
  border-bottom: 1px solid $grey25;
}

.container {
  @include make-container();

  max-width: $container-max-width;

  &.large {
    max-width: $container-outer-max-width;
    padding: 0;
  }
  &.removePaddingMobile {
    padding: 0;
  }
}

.sideBorders {
  @include media-breakpoint-up(lg) {
    border-left: 1px solid $grey25;
    border-right: 1px solid $grey25;
    &.removeLeftBorder {
      border-left: 0;
    }
  }
}
