@import 'styles/import.scss';

.navigation {
  @include tn(opacity visibility);

  opacity: 0;
  visibility: hidden;
  width: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  &.menuOpen {
    opacity: 1;
    visibility: visible;
    :local(.language),
    :local(.social) {
      opacity: 1;
    }
  }

  .menu {
    padding-top: 48px;
    margin: 0;
    @include media-breakpoint-up(md) {
      padding-top: 64px;
    }
  }

  .languageWrapper {
    display: flex;
    padding: 36px 40px 0;
  }

  .language {
    @include text-md;
    @include tn(
      (opacity, color),
      (0.5s, 0.3s),
      (ease-in-out, ease-in-out),
      (0.6s, 0s)
    );

    margin-right: 16px;
    color: $grey50;
    opacity: 0;
    cursor: pointer;

    &:hover {
      color: $blue;
    }

    &.isActive {
      color: $blue;
      &::after {
        content: '';
        display: block;
        padding-top: 4px;
        border-bottom: 2px solid $blue;
      }
    }

    &:last-child {
      transition-delay: 0.6s;
    }

    @include media-breakpoint-up(md) {
      @include tn(
        (opacity, color),
        (0.5s, 0.5s),
        (ease-in-out, ease-in-out),
        (0.4s, 0s)
      );

      &:last-child {
        transition-delay: 0.5s;
      }
    }
  }

  .socialWrapper {
    padding: 36px 40px 40px;

    display: flex;
    margin: 0 -12px;
  }

  .social {
    color: $blue;
    @include text-sm;
    padding: 0 12px;
    cursor: pointer;
    opacity: 0;
    @include tn(
      (opacity, color),
      (1s, 0.5s),
      (ease-in-out, ease-in-out),
      (0s, 0s)
    );
    &:hover {
      color: $blue_light;
    }
    &:active {
      color: $blue;
    }
  }
}
