@import 'styles/import.scss';

.teaserBar {
  background-color: $blue_dark;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  padding: 37px 16px;
  @include media-breakpoint-up(md) {
    padding: 37px 48px;
  }
}

.label {
  display: none;
  color: $white;
  @include text-xl;
  &.logo {
    display: block;
    cursor: pointer;
  }
  @include media-breakpoint-up(md) {
    display: block;
  }
}

.teaser {
  border-bottom: 1px solid $grey25;
}

.teaserWrapper {
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.teaserContainer {
  position: relative;
  @include media-breakpoint-up(md) {
    @include make-col(10.5);
    border-left: 1px solid $grey25;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(11);
  }
}

.teaserIcon {
  display: none;
  border-left: 1px solid $grey25;
  @include media-breakpoint-up(md) {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 40px;
    border-right: 1px solid $grey25;
  }
}

.imageWrapper {
  padding-bottom: 120px;
  @include media-breakpoint-up(md) {
    @include make-col(8.5, 10.5);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(9, 11);
  }
}

.textWrapper {
  padding: 120px 40px;
  color: $blue_dark;
  @include media-breakpoint-up(md) {
    padding: 120px 120px 240px 40px;
    &.hasSamllerMarginBottom {
      padding-bottom: 120px;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 240px 240px 120px;
  }
}

.title {
  @include heading-4;
  margin-bottom: 24px;
}

.subtitle {
  @include text-xl;
}
