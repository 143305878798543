@import 'styles/import.scss';

.header {
  @include tn(
    (background, transform),
    (100ms, 300ms),
    (ease-in, ease-in),
    (300ms, 300ms)
  );

  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background: transparent;
  z-index: 100;
  pointer-events: none;
  &.backgroundHeader {
    background: $white;
  }

  &.menuOpen {
    pointer-events: initial;

    &.hideNav {
      transform: translateY(0);
    }

    :global(.hamburger-inner),
    :global(.hamburger.is-active .hamburger-inner) {
      background-color: $blue;
      &::after,
      &::before {
        background-color: $blue;
      }
    }
    .navigation {
      &.backgroundHeader {
        button {
          background-color: transparent;
        }
        &:hover {
          button {
            background-color: transparent;
          }
        }
      }
    }

    :global(.hamburger) {
      border-color: $blue;
      &:hover {
        opacity: 1;
        border-color: $blue_light;
        :global(.hamburger-inner) {
          background: $blue_light;

          &:after,
          &:before {
            background-color: $blue_light;
          }
        }
      }
    }

    :local(.headerWrapper) {
      &:after,
      &:before {
        opacity: 1;
      }
    }
  }

  .headerWrapper {
    max-width: 1600px;
    margin: -100px auto;
    position: relative;
    &:after,
    &:before {
      content: '';
      @include tn(opacity, 0.5s, ease-in-out, 0s);
      background-color: rgba($blue_dark, 0.5);
      width: calc((100vw - ((100vw - 100%) / 2)));
      height: 100%;
      position: absolute;
      top: 0;
      left: calc((100vw - 100%) / -2);
      z-index: 1;
      opacity: 0;
    }
    &:before {
      z-index: 2;
      background-color: $white;
      width: calc((100vw - 100%) / 2);
      left: auto;
      right: calc((100vw - 100%) / -2);
    }
  }

  .headerContainer {
    max-width: 560px;
    margin-left: auto;
    height: 100vh;
    position: relative;
    z-index: 2;
    background-color: $white;
    @include tn(
      (opacity, transform),
      (0.7s, 0.7s),
      (ease-in-out, ease-in-out),
      (0s, 0.2s)
    );
    opacity: 0;
    transform: translateX(100%);
    &.isOpen {
      transition-delay: 0s;
      opacity: 1;
      transform: translateX(0);
    }
  }

  .navigationWrapper {
    position: relative;
    max-width: $container-max-width;
    margin: 0 auto;
    height: 100px;
    width: 100%;
  }

  .navigation {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    padding: 24px;
    position: absolute;
    z-index: 3;
    width: fit-content;
    right: 0;
    pointer-events: all;
    &:not(.backgroundHeader) {
      &:hover {
        :global(.hamburger) {
          border-color: $blue_light;
          :global(.hamburger-inner) {
            background-color: $blue_light;
            &::after,
            &::before {
              background-color: $blue_light;
            }
          }
        }
      }
    }
    &.backgroundHeader {
      button {
        background-color: $blue;
      }
      &:hover {
        button {
          background-color: $blue_light;
        }
        :global(.hamburger) {
          border-color: $blue_light;
          opacity: 1;
        }
      }
      :global(.hamburger) {
        border-color: $blue;
      }
      :global(.hamburger-inner) {
        background-color: $white;
        &::before,
        &::after {
          background-color: $white;
        }
      }
    }
  }

  .logo {
    height: 16px;
    width: auto;
  }

  :global(.hamburger) {
    padding: 0;
    display: flex;
    border: 1.5px solid $white;
    border-radius: 50%;
    width: 52px;
    justify-content: center;
    height: 52px;
    align-items: center;
    opacity: 1;
    @include tn((border-color, background-color), 0.5s, ease-in-out, 0s);

    :global(.hamburger-inner) {
      @include tn(
        (background-color, transform),
        (0.5s, 0.2s),
        (ease-in-out, cubic-bezier(0.55, 0.055, 0.675, 0.19)),
        (0s, 0s)
      );

      &:before {
        @include tn(
          (background-color, top, opacity),
          (0.5s, 0.2s, 0.2s),
          (ease-in-out, ease, ease),
          (0s, 0.4s, 0s)
        );
      }
      &:after {
        @include tn(
          (background-color, bottom, transform),
          (0.5s, 0.2s, 0.2s),
          (ease-in-out, ease, cubic-bezier(0.55, 0.055, 0.675, 0.19)),
          (0s, 0.4s, 0s)
        );
      }
    }
    &:global(.is-active) {
      :global(.hamburger-inner) {
        @include tn(
          (background-color, transform),
          (0.5s, 0.2s),
          (ease-in-out, cubic-bezier(0.215, 0.61, 0.355, 1)),
          (0s, 0.4s)
        );
        &:before {
          @include tn(
            (background-color, top, opacity),
            (0.5s, 0.2s, 0.2s),
            (ease-in-out, ease, ease),
            (0s, 0s, 0.4s)
          );
        }
        &:after {
          @include tn(
            (background-color, bottom, transform),
            (0.5s, 0.2s, 0.2s),
            (ease-in-out, ease, cubic-bezier(0.215, 0.61, 0.355, 1)),
            (0s, 0s, 0.4s)
          );
        }
      }
    }
  }

  :global(.hamburger-inner),
  :global(.hamburger.is-active .hamburger-inner) {
    background-color: $white;
    &::after,
    &::before {
      background-color: $white;
    }
  }

  @include media-breakpoint-up(md) {
    :global(.hamburger) {
      border-color: $blue;
    }
    :global(.hamburger-inner),
    :global(.hamburger.is-active .hamburger-inner) {
      background-color: $blue;
      &::after,
      &::before {
        background-color: $blue;
      }
    }

    .navigation {
      @include make-col(1.5);
      justify-content: center;
      width: 100%;
      &.backgroundHeader {
        background: transparent;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .navigation {
      @include make-col(1);
    }
  }
}
