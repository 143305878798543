@import './import.scss';
@import 'common/reboot.scss';
@import 'common/helper.scss';
@import 'config/variables.scss';
@import 'mixins/grid.scss';
@import 'mixins/typography';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
$aos-distance: 60px;
@import 'aos/src/sass/aos.scss';

$hamburger-layer-border-radius: 0px;
$hamburger-layer-height: 2px;
$hamburger-layer-width: 24px;
$hamburger-layer-spacing: 6px;
$hamburger-active-layer-color: white;
$hamburger-layer-color: white;
@import '~hamburgers/_sass/hamburgers/hamburgers';

/**
 * Global
 */
a {
  @include tn(color, 0.5s, ease-in-out, 0s);
  color: $blue_light;
  @include border-underline;
  display: inline-block;
  width: fit-content;
  &:hover {
    text-decoration: none;
    color: $blue;
  }

  &:focus {
    outline: 0;
  }
}

button,
input[type='button'] {
  border: 0;
  background: transparent;
  padding: 0;

  &:focus {
    outline: none;
  }
}

/**
 * Block editor color palette utilities
 * @see https://git.io/JeyD6
 */

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 16px;
  font-weight: 400;
}

body {
  @include font-family;
  @include text-md;

  color: $black;
}

body ::selection {
  background-color: $selection;
}

body.no-scroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

.ReactCollapse--collapse {
  transition: height 500ms;
  height: 0;
}
