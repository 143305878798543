@import 'styles/import.scss';

.item {
  position: relative;
  @include tn(
    (opacity, transform),
    (1s, 1s),
    (ease-in-out, ease-out),
    (0s, 0s)
  );
  opacity: 0;
  transform: translateY(20px);
  &.activeAniamtion {
    opacity: 1;
    transform: translateY(0);
  }
  &.hasChildren {
    svg {
      @include tn(transform);
    }

    &.subMenuOpen {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.link {
  @include heading-5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 12px 40px;
  color: $blue;
  cursor: pointer;
  &:after,
  &::before {
    display: none;
  }
  &:hover {
    color: $blue_light;
  }
  &:active {
    color: $blue;
  }
}

.subMenu {
  padding: 4px 24px;

  .link {
    padding-bottom: 8px;
    @include text-lg;

    text-transform: none;
  }
}
