@import 'styles/import.scss';

.footer {
  background: $green_dark;
  padding: 88px 0 72px;
  color: $grey10;
}

.formWrapper {
  margin-bottom: 80px;
}

.legalWrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 24px 16px 0;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
}

.legalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    margin-bottom: 0;
    &::before {
      display: none;
    }
    + span {
      margin-right: 8px;
      margin-left: 8px;
      position: initial;
      display: inline;
      width: auto;
      height: auto;
      pointer-events: none;
    }
  }
}

.headline {
  @include text-xs;

  color: rgba($white, 0.3);
  margin-bottom: 72px;
}

.linkLegal,
.link {
  color: $white;
  display: inline-flex;
  margin-bottom: 12px;
  align-items: center;

  svg {
    margin-left: 8px;
  }

  &:hover {
    color: white;
  }
}
.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.linkLegal {
  @include text-xs;
  cursor: pointer;
  color: rgba($white, 0.3);
  margin-bottom: 0;
}

.link {
  @include text-sm;
  &:before {
    background: $green_spot;
  }
  &:hover {
    color: $green_spot;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.contact {
  @include text-sm;
  white-space: pre-line;
  margin-top: 48px;
  margin-bottom: 48px;

  div:first-child {
    margin-bottom: 16px;
  }

  a {
    @include hover-border;

    color: $white;
  }
}

.copyright {
  @include text-xs;
  margin-top: 8px;
}

.socialArrowIconWrapper {
  margin-left: 8px;
}

.menu {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 120px;
  column-gap: 64px;
}

.button {
  margin-top: 32px;
}
@include media-breakpoint-up(md) {
  .footer {
    padding: 104px 0 72px;
  }

  .formWrapper {
    @include make-col(6);
    margin-bottom: 0;
    > div {
      padding: 0 40px;
    }
  }

  .menu {
    margin-bottom: 0;
    width: 100%;
    justify-content: space-between;
    column-gap: initial;
  }

  .inner {
    display: flex;
    margin-bottom: 120px;
  }

  .contactWrapper {
    @include make-col(3, 6);
  }

  .copyright {
    margin-top: 0;
  }

  .contact {
    margin-bottom: 80px;
  }

  .section {
    @include make-col(3, 6);
  }

  .legalWrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0 40px;
    padding: 24px 0 0;
  }
}
@include media-breakpoint-up(lg) {
  .inner {
    display: flex;
  }

  .section {
    padding-left: 80px;
  }
  .formWrapper {
    > div {
      padding: 0;
      padding-left: 80px;
    }
  }
  .legalWrapper {
    margin: 0 80px;
  }
}
@include media-breakpoint-up(xl) {
  .formWrapper {
    > div {
      padding-right: 240px;
    }
  }
}
