@mixin circle($value, $border: null) {
  width: $value;
  height: $value;
  display: inline-block;

  @if $border != null {
    border: $border;
  }

  border-radius: 100%;
}

@mixin make-container() {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: 40px;
  padding-left: 40px;
  max-width: $container-max-width;
  @include media-breakpoint-up(md) {
    padding-right: 0;
    padding-left: 0;
  }
}

@mixin make-full-width() {
  margin-left: -$gutter-width;
  margin-right: -$gutter-width;

  @include media-breakpoint-up(xl) {
    width: 100vw;
    margin-left: calc((100vw - #{$container-max-width}) / -2);
  }
}

@mixin make-row {
  margin: 0 -$gutter-width;
  display: flex;
  flex-wrap: wrap;
}

@mixin hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
